import { ProductList } from 'types/Product';
import { Wishlist, WishlistProductsResponse, WishlistSocialShareResponse } from 'types/Wishlist';
import { apiClient } from '../../helpers/ApiClient';
import { logErrorResponse } from '../../utils/loggerUtil';

const fetchWishlist = async (wishlistId?: string, productCode?: string) => {
  try {
    const { data } = await apiClient<Wishlist>({
      params: {
        productCode,
        wishlistId,
      },
      url: `/wishlist`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Wishlist Connector', error, 'Failed fetching wishlists');
    throw error;
  }
};

type FetchWishlistProductsParams = {
  currentPage?: number;
  pageSize?: number;
  wishlistId: string;
};

const fetchWishlistProducts = async ({ currentPage = 0, pageSize = 12, wishlistId }: FetchWishlistProductsParams) => {
  try {
    const { data } = await apiClient<WishlistProductsResponse>({
      params: {
        currentPage,
        fields: 'FULL',
        pageSize,
      },
      url: `/wishlist/${wishlistId}/products`,
    });

    return data;
  } catch (error) {
    logErrorResponse(
      'Wishlist Connector',
      error,
      `Failed fetching wishlist products for wishlist with id: ${wishlistId}`,
    );
    throw error;
  }
};

export type EditWishlistParams = {
  description?: string;
  title?: string;
  wishlistId: string;
};

const editWishlist = async ({ description, title, wishlistId }: EditWishlistParams) => {
  // This api call does not accept request body, Axios cannot send params with POST
  try {
    const { data } = await apiClient<Wishlist>({
      method: 'post',
      params: {
        description,
        title,
      },
      url: `/wishlist/edit/${wishlistId}`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Wishlist Connector', error, `Failed editing wishlist with id: ${wishlistId}`);
    throw error;
  }
};

export type RemoveWishlistParams = {
  wishlistId: string;
};

const removeWishlist = async ({ wishlistId }: RemoveWishlistParams) => {
  try {
    await apiClient<Wishlist>({
      method: 'delete',
      url: `/wishlist/remove/${wishlistId}`,
    });
  } catch (error) {
    logErrorResponse('Wishlist Connector', error, `Failed removing wishlist with id: ${wishlistId}`);
    throw error;
  }
};

export type RemoveWishlistProductParams = {
  productCode: string;
  wishlistId: string;
};

const removeWishlistProduct = async ({ productCode, wishlistId }: RemoveWishlistProductParams) => {
  try {
    const { data } = await apiClient<WishlistProductsResponse>({
      method: 'delete',
      params: {
        productCode,
        wishlistId,
      },
      url: `/wishlist/remove`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Wishlist Connector', error, `Failed removing product from wishlist with id: ${wishlistId}`);
    throw error;
  }
};

export type AddWishlistParams = {
  description?: string;
  title?: string;
};

const addWishlist = async ({ description, title }: AddWishlistParams) => {
  // This api call does not accept request body, Axios cannot send params with POST
  try {
    const { data } = await apiClient<Wishlist>({
      method: 'post',
      params: {
        description,
        isPublic: true,
        title,
      },
      url: `/wishlist/add`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Wishlist Connector', error, `Failed adding wishlist `);
    throw error;
  }
};

export type AddProductToWishlistParams = {
  productCode: string;
  wishlistId: string;
};

const addProductToWishlist = async ({ productCode, wishlistId }: AddProductToWishlistParams) => {
  try {
    const { data } = await apiClient<Wishlist>({
      method: 'post',
      params: {
        productCode,
        wishlistId,
      },
      url: `/wishlist/add`,
    });

    return data;
  } catch (error) {
    logErrorResponse(
      'Wishlist Connector',
      error,
      `Failed to add product (${productCode}) to wishlist for list with id ${wishlistId}`,
    );
    throw error;
  }
};

export type RemoveProductFromWishlistParams = {
  productCode: string;
  wishlistId: string;
};

const removeProductFromWishlist = async ({ productCode, wishlistId }: RemoveProductFromWishlistParams) => {
  try {
    const { data } = await apiClient<WishlistProductsResponse>({
      method: 'delete',
      params: {
        productCode,
        wishlistId,
      },
      url: `/wishlist/remove`,
    });

    return data;
  } catch (error) {
    logErrorResponse(
      'Wishlist Connector',
      error,
      `Failed to remove product (${productCode}) from wishlist for list with id ${wishlistId}`,
    );
    throw error;
  }
};

export type FetchWishlistShareLinksParams = {
  wishlistId: string;
};

const fetchWishlistShareLinks = async ({ wishlistId }: FetchWishlistShareLinksParams) => {
  try {
    const { data } = await apiClient<WishlistSocialShareResponse>({
      params: { wishlistId },
      url: `/wishlist/socialSharing`,
    });

    return data;
  } catch (error) {
    logErrorResponse(
      'Wishlist Connector',
      error,
      `Failed fetching wishlist sharing links for list with id ${wishlistId}`,
    );
    throw error;
  }
};

export type FetchWishlistProductsByCodesParams = {
  productCodes: string[];
};

const fetchWishlistProductsByCodes = async ({ productCodes }: FetchWishlistProductsByCodesParams) => {
  try {
    const { data } = await apiClient<WishlistProductsResponse>({
      params: { productCode: productCodes },
      url: `/wishlist/products`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Wishlist Connector', error, `Failed fetching products for anonymous wishlist`);
    throw error;
  }
};

export type FetchWishlistRealtimeInfoParams = {
  productCodes: string[];
};

const fetchWishlistRealtimeInfo = async ({ productCodes }: FetchWishlistRealtimeInfoParams) => {
  try {
    const { data } = await apiClient<ProductList>({
      params: { productCodes },
      url: `/wishlist/realtimeinformation`,
    });

    return data;
  } catch (error) {
    logErrorResponse(
      'Wishlist Connector',
      error,
      `Failed fetching realtime info for wishlists with product codes: ${productCodes}`,
    );
    throw error;
  }
};

export {
  addProductToWishlist,
  addWishlist,
  editWishlist,
  fetchWishlist,
  fetchWishlistProducts,
  fetchWishlistProductsByCodes,
  fetchWishlistRealtimeInfo,
  fetchWishlistShareLinks,
  removeProductFromWishlist,
  removeWishlist,
  removeWishlistProduct,
};
