import { fetchWishlistRealtimeInfo } from 'features/wishlist/connector';
import { Product } from 'types/Product';
import { log } from 'utils/loggerUtil';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface WishlistState {
  showWishListOverlay: boolean;
  showWishlistSuccessMessage: boolean;
  wishlistProduct?: Product;
  wishlistRealTimeInfo: Product[];
}

export interface WishlistStore extends WishlistState {
  actions: {
    fetchWishlistRealtimeInfo: (productCodes?: string[]) => Promise<void>;
    setShowWishlistSuccessMessage: (show: boolean) => void;
    toggleWishListOverlay: (show: boolean, wishlistProduct?: Product) => void;
  };
}

const initialState: WishlistState = {
  showWishListOverlay: false,
  showWishlistSuccessMessage: false,
  wishlistProduct: undefined,
  wishlistRealTimeInfo: [],
};

export const useWishlistStore = create<WishlistStore>()(
  devtools(
    (set) => ({
      ...initialState,
      actions: {
        fetchWishlistRealtimeInfo: async (productCodes = []) => {
          try {
            const result = await fetchWishlistRealtimeInfo({ productCodes });
            set(() => ({ wishlistRealTimeInfo: result?.products || [] }));
          } catch (error) {
            log('WishlistStore - fetchWishlistRealtimeInfo', 'Error fetching wishlist realtime info', error);
            throw error;
          }
        },
        setShowWishlistSuccessMessage: (showWishlistSuccessMessage) => set(() => ({ showWishlistSuccessMessage })),
        toggleWishListOverlay: (showWishListOverlay, wishlistProduct) =>
          set(() => ({ showWishListOverlay, wishlistProduct })),
      },
    }),
    { name: 'wishlistStore' },
  ),
);

export const useWishlistActions = () => useWishlistStore((state) => state.actions);
export const useShowWishlistSuccessAction = () => useWishlistStore((state) => state.showWishlistSuccessMessage);
export const useWishlistRealTimeInfo = () => useWishlistStore((state) => state.wishlistRealTimeInfo);
export const useShowWishListOverlay = () => useWishlistStore((state) => state.showWishListOverlay);
export const useWishlistProduct = () => useWishlistStore((state) => state.wishlistProduct);
